import Axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import Resource from "./Resource";
import {
  Application,
  BankStatementAnalytics,
  Business,
  ConfigItem,
  Iso,
  User,
  File as FileDoc,
  Contract,
  Syndicator,
  ConsumerCreditProfile,
  Product,
  Industry,
  Payment,
  ListTemplate,
  Log,
  Notification,
  CustomerServiceTask,
  UploadActivity,
  Role,
} from "./interfaces";
import Offer from "./models/Offer";
import store from "@/store";

export const http = Axios.create({
  baseURL: process.env.VUE_APP_API_BASE,
});

http.interceptors.request.use((request: AxiosRequestConfig) => {
  const token = store.state.loginAsToken || store.state.token;

  if (token) {
    request.headers["Authorization"] = token;
  }
  if (store.state.loginAsToken) {
    request.headers["Authorization-Agent"] = store.state.token;
  }
  // stop request and return 401 response when no token exist except for login request
  if (
    ![
      "auth/login",
      "auth/visitor",
      "auth/forgot-login",
      "auth/forgot-password",
      "config",
    ].includes(request.url || "") &&
    !token
  ) {
    console.log(
      "[Resource] Trying to request auth required api but no token, redirect to login."
    );
    window.location.href =
      "/login?redirect=" +
      encodeURIComponent(window.location.pathname + window.location.search);
  }

  // if full url length > 1024,
  // transfer to POST request, convert query params into body '_query' field

  let urlLength = request.baseURL?.length || 0;
  urlLength += (request.url?.length || 0) + 1;
  urlLength +=
    Object.keys(request.params || {})
      .map((k) => `${k}=${request.params[k]}`)
      .join("&").length + 1;

  if (urlLength >= 1024 && request.method === "get") {
    request.method = "post";
    if (!request.data) request.data = {};
    request.headers["x-post-as-get"] = 1;
    request.data._query = request.params;
    request.params = undefined;
  }

  return request;
});

http.interceptors.response.use(
  (res) => {
    const apiVer = res.headers["lw-ver"];
    store.commit("SET_API_VERSION", apiVer);
    return res;
  },
  (err) => {
    // console.log("http.interceptors.response", err);
    const { response }: { response: AxiosResponse } = err;
    if (!response) return Promise.reject(err);
    // redirect to login page on any 401 response
    if (response.status === 401) {
      window.localStorage.clear();
      let loginUrl = `/login?redirect=${encodeURIComponent(
        window.location.pathname + window.location.search
      )}`;
      if (store.state.user?.role === Role.VISITOR) {
        loginUrl += `&viaCode=1`;
      }
      console.log("[Resource] Server auth required, redirect to login.");
      window.location.href = loginUrl;
    }
    if (
      response.status === 403 &&
      response.config.headers["Fatal-403"] === "true"
    ) {
      store.commit("SET_FORBIDDEN", true);
    }
    if (response.status === 404) {
      return Promise.reject({ status: 404, message: response.data?.message });
    }
    return Promise.reject(err);
  }
);

export const ApplicationResource = new Resource<Application>(
  http,
  "application/{id}"
);

export const BankStatementAnalyticsResource =
  new Resource<BankStatementAnalytics>(http, "bank-statement-analytics/{id}");

export const BusinessResource = new Resource<Business>(http, "business/{id}");

export const ConfigItemResource = new Resource<ConfigItem>(
  http,
  "config/{key}"
);

export const ContractResource = new Resource<Contract>(http, "contract/{id}");

export const ConsumerCreditProfileResource =
  new Resource<ConsumerCreditProfile>(http, "consumer-credit-profile/{id}");

export const CustomerServiceTaskResource = new Resource<CustomerServiceTask>(
  http,
  "customer-service-task/{id}"
);

export const FileResource = new Resource<FileDoc>(http, "file/{id}");

export const IndustryResource = new Resource<Industry>(http, "industry");

export const IsoResource = new Resource<Iso>(http, "iso/{id}");

export const ListTemplateResource = new Resource<ListTemplate>(
  http,
  "list-template/{id}"
);

export const LogResource = new Resource<Log>(http, "log/{id}");

export const NotificationResource = new Resource<Notification>(
  http,
  "notification/{id}"
);

export const OfferResource = new Resource<Offer>(http, "offer/{id}");

export const PaymentResource = new Resource<Payment>(http, "payment/{id}");

export const ProductResource = new Resource<Product>(http, "product/{id}");

export const SyndicatorResource = new Resource<Syndicator>(
  http,
  "syndicator/{id}"
);

export const UploadActivityResource = new Resource<UploadActivity>(
  http,
  "upload-activity/{id}"
);

export const UserResource = new Resource<User>(http, "user/{id}");
