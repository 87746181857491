import { http } from "@/resources";
import {
  Config,
  externalRoles,
  ListTemplate,
  Product,
  Role,
  User,
} from "@/resources/interfaces";
import store from "@/store";
import type { AxiosRequestConfig } from "axios";

export const config = {} as Config;

async function getHttpData(path: string, config?: AxiosRequestConfig) {
  return new Promise((resolve) => {
    http
      .get(path, config)
      .then((res) => {
        resolve(res?.data);
      })
      .catch((err) => {
        console.error(err.message);
        resolve(undefined);
      });
  });
}

const loadConfig = async (configLoaded: Partial<Config> = {}) => {
  const [commonConfig, user] = await Promise.all([
    getHttpData("config", {
      headers: {
        "Fatal-403": "true",
      },
    }),
    configLoaded.user?.id
      ? configLoaded.user
      : localStorage.token && getHttpData("auth/user"),
  ]);

  if (user) {
    store.commit("SET_AUTH_USER", {
      login: (user as User)?.login,
      role: (user as User)?.role,
      passwordExpiresAt: (user as User)?.passwordExpiresAt,
      tags: (user as User)?.tags,
      isManager: (user as User)?.isManager,
    });
  }

  const configAggregated = {
    ...(commonConfig as Config),
    user: user as User,
  };

  if ([Role.ADMIN, Role.UWER].includes(user.role)) {
    configAggregated.products =
      configLoaded.products || ((await getHttpData("product")) as Product[]);
  }

  if (!externalRoles.includes(user.role) || user.role === Role.ISO) {
    const fixedListTemplates =
      configLoaded.fixedListTemplates ||
      (await getHttpData("list-template?fixed=true"));
    configAggregated.fixedListTemplates =
      ((fixedListTemplates || []) as ListTemplate[]).map((t) => ({
        count: NaN,
        ...t,
      })) || [];
  }

  Object.assign(config, configAggregated);

  return configAggregated;
};

export default loadConfig;

export const defaultConfig = {
  daysPerMonth: 20,
  weeksPerMonth: 4,
  techFee: 299,
  minPrincipal: 10000,
  houseOriginationFee: 0.02,
  vb: 0.01,
  repCommissionDiscount: [],
  repCommissionDiscountByMtdVolume: [],
  stipLabels: [],
  entityTags: [],
  usStates: {},
  mcaContractRules: [],
  docusign: {
    accessToken: "",
    expiresAt: new Date(),
  },
  experian: {
    accessToken: "",
    expiresAt: new Date(),
  },
  applicationIdAutoIncrement: 1,
  riskModelDesc: {},
  schedulePISplitTheta: {
    weekly: 5 / 261,
    dailyDoubleDebit: 1 / 261,
    dailyNonDoubleDebit: 1 / 251,
  },
};
